<template>
  <div>
    <!-- start action buttons -->
    <button
      v-if="permissionChecker('create')"
      @click.prevent="showAddSkuModal"
      class="btn btn--export-filter font-weight-bolder mb-1 mr-3"
    >
      <span class="svg-icon">
        <v-icon size="18">mdi-plus-box-outline</v-icon>
      </span>
      <span>Add SKU</span>
    </button>
    <!-- end action buttons -->
    <AddSkuDialog ref="addSku" />
  </div>
</template>

<script>
import AddSkuDialog from "@/own/components/warehouseManagement/inventoryAdjustments/AddSkuDialog.vue";

export default {
  name: "TopActions",
  components: { AddSkuDialog },
  props: ["permissions"],
  methods: {
    permissionChecker(permType) {
      return this.permissions.includes(permType);
    },
    showAddSkuModal() {
      this.$refs.addSku.toggleModal();
    },
  },
};
</script>
