<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add SKU</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body scroll-y mx-5 mx-xl-15 py-7">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div>
              <v-select
                v-if="!serverData?.is_client"
                dense
                outlined
                clearable
                v-model="formData.customer_id"
                label="Customer"
                item-text="text"
                item-value="index"
                :items="serverData?.clients"
                :error-messages="customerIdErrors"
                @blur="$v.formData.customer_id.$touch()"
                @input="$v.formData.customer_id.$touch()"
              ></v-select>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div>
              <v-text-field
                dense
                outlined
                clearable
                v-model="formData.location"
                label="Location"
                :error-messages="locationErrors"
                @blur="onBlurLocation"
                @input="$v.formData.location.$touch()"
              ></v-text-field>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div>
              <v-text-field
                v-model="formData.sku_barcode"
                label="SKU"
                clearable
                outlined
                :disabled="disabled.sku_barcode"
                dense
                :error-messages="skuErrors"
                append-icon="mdi-barcode-scan"
                @input="$v.formData.sku_barcode.$touch()"
                @blur="$v.formData.sku_barcode.$touch()"
              ></v-text-field>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div>
              <v-text-field
                v-model="formData.qty"
                label="Quantity"
                clearable
                type="number"
                outlined
                :disabled="disabled.qty"
                dense
                :error-messages="qtyErrors"
                @input="$v.formData.qty.$touch()"
                @blur="onBlurQty"
              ></v-text-field>
            </div>
          </div>
        </div>
        <!--begin::Actions-->
        <div class="d-flex justify-center pt-7 pb-4">
          <button
            @click="submit"
            type="submit"
            class="btn btn--export-filter mr-3 px-5 py-3"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
        <!--begin::Body-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

import ApiService from "@/core/services/api.service";

import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { UPDATE_TABLE_DATA } from "@/core/services/store/inventoryAdjustment.module.js";

export default {
  name: "AddSkuDialog",
  mixins: [validationMixin],
  validations: {
    formData: {
      customer_id: { required },
      location: { required },
      sku_barcode: {
        required: requiredIf(function () {
          return !this.serverData?.is_client;
        }),
      },
      qty: { required },
    },
  },
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      customer_id: null,
      location: null,
      sku_barcode: null,
      qty: null,
    },
    disabled: {
      sku_barcode: true,
      qty: true,
    },
  }),
  methods: {
    toggleModal() {
      if (!this.dialog) {
        this.$store.commit(SET_PAGE_LOADING, true);

        ApiService.post(
          `/warehouse_management/inventory_adjustments/create_for_untracked_sku`
        )
          .then(({ data }) => {
            this.serverData = data;
          })
          .then(() => {
            this.clearFields();
            this.dialog = !this.dialog;
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      } else {
        this.dialog = false;
      }
    },
    classSecondText() {
      return "second-text";
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    async validateLocation() {
      if (this.formData.location) {
        this.$store.commit(SET_PAGE_LOADING, true);
        const payload = {
          warehouse_id: this.selectedWarehouse,
          location: this.formData.location,
        };
        try {
          await ApiService.post(
            `/warehouse_management/warehouses/check_locked_location`,
            payload
          );
          this.disabled = {
            sku_barcode: false,
            qty: false,
          };
        } finally {
          this.$store.commit(SET_PAGE_LOADING, false);
        }
      }
    },
    onBlurLocation() {
      this.$v.formData.location.$touch();
      this.validateLocation();
    },
    onBlurQty() {
      if (this.formData.qty < 0) {
        this.formData.qty = 1;
      }
      this.$v.formData.qty.$touch();
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const payload = {
        warehouse_id: this.selectedWarehouse,
        customer_id: this.formData.customer_id,
        location: this.formData.location,
        sku_barcode: this.formData.sku_barcode,
        qty: this.formData.qty,
      };

      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post(
        `/warehouse_management/inventory_adjustments/store_for_untracked_sku`,
        payload
      )
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: "SKU added",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
        })
        .then(() => {
          this.toggleModal();
        })
        .then(() => {
          this.$store.dispatch(UPDATE_TABLE_DATA, {
            warehouse_id: this.selectedWarehouse,
          });
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    clearFields() {
      this.formData = {
        warehouse_id: null,
        customer_id: null,
        location: null,
        sku_barcode: null,
        qty: null,
      };
      this.disabled = {
        sku_barcode: true,
        qty: true,
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
  computed: {
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    customerIdErrors: function () {
      return this.handleFormValidation("customer_id");
    },
    locationErrors: function () {
      return this.handleFormValidation("location");
    },
    skuErrors: function () {
      return this.handleFormValidation("sku_barcode");
    },
    qtyErrors: function () {
      return this.handleFormValidation("qty");
    },
  },
};
</script>
